import { Link } from "react-router-dom";
import styles from "./games-list-new.module.css";
import { useState } from "react";
import { trackGoal } from "../../hooks/track-goal";

import blueSpot from "./images/blueSpot.png";
import greenSpot from "./images/greenSpot.png";
import orangeSpot from "./images/orangeSpot.png";
import cubes from "./images/cubes.png";

function getSoundLetter(description) {
  const match = description.match(/'([^']+)/);
  if (!match) return "";
  const letters = match[1].split(",")[0].trim();
  return letters.charAt(0).toLowerCase();
}

function normalizeString(str) {
  if (!str) return "";
  return str
    .toLowerCase()

    .replace(/['"“”«»]+/g, "")
    .trim();
}

export function GamesListNew({ gamesData, activeCategory }) {
  const handleClick = () => {
    // console.log("click");
  };

  const [searchTerm, setSearchTerm] = useState("");

  const normalizedSearch = normalizeString(searchTerm);

  const filteredGames = gamesData.filter((game) => {
    return (
      game.category === activeCategory.category &&
      (!activeCategory.subcategory ||
        game.subcategory === activeCategory.subcategory)
    );
  });

  const uniqueGames = filteredGames.reduce((acc, currentGame) => {
    const isDuplicate = acc.some((game) => game.title === currentGame.title);
    if (!isDuplicate) {
      acc.push(currentGame);
    }
    return acc;
  }, []);

  if (activeCategory.category === "Звукопроизношение") {
    uniqueGames.sort((a, b) => {
      const letterA = getSoundLetter(a.description);
      const letterB = getSoundLetter(b.description);
      return letterA.localeCompare(letterB);
    });
  }


  const finalGames = uniqueGames.filter((game) => {
    // Формируем строку для игры (название + описание) или даже + категория
    const combinedText = (game.title + " " + game.description)
      .toLowerCase()
      // тоже вычищаем кавычки, пробелы:
      .replace(/['"“”«»]+/g, "")
      .trim();

    // Если пользователь ничего не ввёл, либо combinedText содержит normalizedSearch
    if (!normalizedSearch) {
      return true;
    }
    return combinedText.includes(normalizedSearch);
  });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchKeyDown = (event) => {
    if (event.key === "Enter") {
      console.log("Поиск по:", searchTerm);
    }
  };

  const handleSearchFocus = () => {
    trackGoal("click_search_cabinet"); 
  };

  return (
    <div className={styles.centerContainer}>
      <div className={styles.searchBar}>
        <div className={styles.searchIcon}></div>
        <input
          type="text"
          className={styles.searchInput}
          placeholder="Поиск игр..."
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyDown={handleSearchKeyDown}
          onFocus={handleSearchFocus}

        />
      </div>

      <div className={styles.containerWrapper}>
      {finalGames.length >= 4 && (
          <>
            <img src={blueSpot} className={styles.blueSpot} alt="пятно" />
            <img src={greenSpot} className={styles.greenSpot} alt="пятно" />
            <img src={orangeSpot} className={styles.orangeSpot} alt="пятно" />

            <img src={cubes} className={styles.cubes} alt="кубики" />
          </>
        )}
        <div className={styles.container}>
        {finalGames.map((game) => (
            <div key={game.id} className={styles.gameContainer}>
              <div className={styles.imageContainer}>
                <img
                  src={game.imageUrl}
                  alt={game.title}
                  className={styles.image}
                />
                <h4 className={styles.gameHeader}>{game.title}</h4>
                <p className={styles.gameDescription}>{game.description}</p>
                <Link to={game.path}>
                  <button className={styles.btn} onClick={handleClick}>
                    Играть
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default GamesListNew;
