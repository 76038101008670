import React, { useState } from "react";
import styles from "./pricing.module.css";
import LayoutTitleDescription from "../../hooks/layout-title-description";
import { trackGoal } from "../../hooks/track-goal";

function Pricing({ openPromocodeModal, handleSubscription }) {
  const openModalWithSubscription = (duration) => {
    openPromocodeModal(duration);
  };

  return (
    <>
      <LayoutTitleDescription
        pageTitle="Тарифы - Edland"
        pageDescription="Информация о тарифах"
      />
      <section className={styles.sectionPricing} id="pricing">
        <h3 className={styles.headerPricing}>Выберите оптимальный тариф</h3>
        <div className={styles.pricingContainer}>
          <div className={`${styles.container} ${styles.blue}`}>
            <h4 className={`${styles.subGames} ${styles.backgroundBlue}`}>
              Подписка на месяц
            </h4>
            <span className={styles.firtsElement}>В месяц</span>
            <span className={`${styles.price} ${styles.bluePrice}`}>500 ₽</span>
            <span className={styles.secondElementEmpty}></span>
            <div className={styles.btnContainer}>
              <button
                className={`${styles.btn} ${styles.btnBlue}`}
                onClick={() => {
                  trackGoal("click_tarif500_cabinet");
                  openModalWithSubscription(1);
                }}
              >
                Оформить подписку на месяц
              </button>
            </div>
            <div className={styles.imageGirlPuzzle}></div>
          </div>

          <div className={`${styles.container} ${styles.red}`}>
            <h4 className={`${styles.subGames} ${styles.backgroundRed}`}>
              Подписка на 6 месяцев
            </h4>
            <span className={styles.firtsElement}>Всего за</span>
            <span className={`${styles.price} ${styles.redPrice}`}>
              2 500 ₽
            </span>
            <span className={styles.secondElement}>3 000 ₽</span>
            <div className={styles.btnContainer}>
              <button
                className={`${styles.btn} ${styles.btnRed}`}
                onClick={() => {
                  trackGoal("click_tarif2500_cabinet");
                  openModalWithSubscription(6);
                }}
              >
                Оформить подписку на полгода
              </button>
            </div>
            <div className={styles.imageWomanBook}></div>
          </div>
          <div className={`${styles.container} ${styles.green}`}>
            <h4 className={`${styles.subGames} ${styles.backgroundGreen}`}>
              Подписка на 12 месяцев
            </h4>
            <span className={styles.firtsElement}>Всего за</span>
            <span className={`${styles.price} ${styles.greenPrice}`}>
              4 500 ₽
            </span>
            <span className={styles.secondElement}>6 000 ₽</span>
            <div className={styles.btnContainer}>
              <button
                className={`${styles.btn} ${styles.btnGreen}`}
                onClick={() => {
                  trackGoal("click_tarif4500_cabinet");
                  openModalWithSubscription(12);
                }}
              >
                Оформить подписку на год
              </button>
            </div>

            <div className={styles.imageFamily}></div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Pricing;
