import { useState, useEffect } from "react";
import styles from "./games-menu-new.module.css";
import { NavLink } from "react-router-dom";
import { trackGoal } from "../../hooks/track-goal";

function GamesMenuNew({ gamesData, activeCategory, setActiveCategory }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({
    Звукопроизношение: true,
    "Развитие речи": false,
    "Слуховое восприятие": false,
    "Память, внимание и логика": false,
    Математика: false,
    "Окружающий мир": false,
  });
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (activeCategory && activeCategory.category) {
      // Открываем именно эту категорию
      setDropdownOpen((prev) => {
        const newState = {};
        Object.keys(prev).forEach((cat) => {
          newState[cat] = cat === activeCategory.category;
        });
        return newState;
      });
    }
  }, [activeCategory.category]);

  const isSubcategoryActive = (
    categoryName,
    subcategoryName,
    subcategoryCount
  ) => {
    const isChosen =
      activeCategory.category === categoryName &&
      activeCategory.subcategory === subcategoryName;

    if (isChosen) {
      return styles.activeSubcategory;
    }

    if (subcategoryCount > 0) {
      return styles.subcategoryGames;
    }

    return styles.nonActiveLink;
  };

  const isCategoryActive = (categoryName) => {
    return activeCategory.category === categoryName ? styles.activeLink : "";
  };

  const handleCategoryClick = (event, categoryName) => {
    event.stopPropagation();
    // Ставим активную категорию (без подкатегории)
    setActiveCategory({ category: categoryName, subcategory: null });

    const goalName = CATEGORY_GOALS[categoryName];
    if (goalName) {
      trackGoal(goalName);
    }

    // Закрываем все категории, кроме этой
    setDropdownOpen((prev) => {
      const newState = {};
      // пробегаемся по ключам (названиям категорий)
      Object.keys(prev).forEach((cat) => {
        if (cat === categoryName) {
          // открываем текущую (и если она была уже открыта, можем инвертнуть:
          newState[cat] = !prev[cat];
        } else {
          // все остальные закрыты
          newState[cat] = false;
        }
      });
      return newState;
    });
  };

  const handleSubcategoryClick = (event, categoryName, subcat) => {
    event.stopPropagation();
    // Выделяем эту подкатегорию
    setActiveCategory({ category: categoryName, subcategory: subcat });

    const subGoals = SUBCATEGORY_GOALS[categoryName] || {};
    const goalName = subGoals[subcat];
    if (goalName) {
      trackGoal(goalName);
    }

    // Делаем так, чтобы соответствующая категория была открыта, а все остальные — закрыты
    setDropdownOpen((prev) => {
      const newState = {};
      Object.keys(prev).forEach((cat) => {
        if (cat === categoryName) {
          newState[cat] = true; // держим категорию открытой
        } else {
          newState[cat] = false;
        }
      });
      return newState;
    });
  };

  const countGames = (gamesData) => {
    return gamesData.reduce((acc, { category, subcategory }) => {
      acc[category] = acc[category] || { total: 0, subcategories: {} };
      acc[category].total++;
      acc[category].subcategories[subcategory] =
        (acc[category].subcategories[subcategory] || 0) + 1;
      return acc;
    }, {});
  };

  const categoryOrder = [
    "Звукопроизношение",
    "Развитие речи",
    "Слуховое восприятие",
    "Память, внимание и логика",
    "Математика",
    "Окружающий мир",
  ];

  const gamesCount = countGames(gamesData);

  const CATEGORY_GOALS = {
    Звукопроизношение: "category1_zvukoproiznoshenie",
    "Развитие речи": "category1_razvitie_rechi",
    "Слуховое восприятие": "category1_sluhovoe_vospriyatie",
    "Память, внимание и логика": "category1_pamyat_vnimanie_logika",
    Математика: "category1_matematika",
    "Окружающий мир": "category1_okruzhaushi_mir",
  };

  const SUBCATEGORY_GOALS = {
    Звукопроизношение: {
      "Автоматизация звуков": "category2_Avtomatizaciya_zvukov",
      "Дифференциация звуков": "category2_differenciaciya_zvukov",
    },

    "Развитие речи": {
      Лексика: "category2_leksika",
      "Связная речь": "category2_svyaznaya_rech",
      Грамматика: "category2_grammatika",
    },

    "Слуховое восприятие": {
      "Фонетический и фонематический": "category2_fonet_i_fonemat",
      "Неречевые звуки": "category2_nerechevii_zvuki",
    },

    "Память, внимание и логика": {
      "Логические задачи": "category2_logicheskie_zadachi",
      "Исключение лишнего": "category2_iskluchenie_lishnego",
      Внимание: "category2_vnimanie",
      Память: "category2_pamyat",
    },

    Математика: {
      "Цифры, счет": "category2_cifri_shet",
      Сравнение: "category2_sravnenie",
      Фигуры: "category2_figuri",
    },

    "Окружающий мир": {
      "Явления природы": "category2_yavlenia_prirody",
      "Мир человека": "category2_mir_cheloveka",
    },
  };

  const renderSubcategories = (categoryName, subcategories) => {
    return (
      <ul className={styles.dropdown}>
        {Object.keys(subcategories).map((subcategory) => (
          <li
            key={subcategory}
            onClick={(event) =>
              handleSubcategoryClick(event, categoryName, subcategory)
            }
            className={isSubcategoryActive(
              categoryName,
              subcategory,
              subcategories[subcategory]
            )}
          >
            {subcategory}{" "}
            <span className={styles.quantityGamesText}>
              ({subcategories[subcategory]})
            </span>
          </li>
        ))}
      </ul>
    );
  };

  const renderCategories = () => {
    return categoryOrder.map((categoryName) => (
      <li
        key={categoryName}
        className={
          dropdownOpen[categoryName]
            ? `${styles.liBase} ${styles.liOpen}`
            : styles.liBase
        }
        onClick={(event) => handleCategoryClick(event, categoryName)}
      >
        <NavLink className={isCategoryActive(categoryName)}>
          <div className={styles.categoryContainer}>
            <span className={styles.categoryName}>{categoryName}</span>
            <span className={styles.quantityGamesText}>
              ({gamesCount[categoryName]?.total || 0})
            </span>
          </div>
        </NavLink>
        {dropdownOpen[categoryName] &&
          renderSubcategories(
            categoryName,
            gamesCount[categoryName]?.subcategories || {}
          )}
      </li>
    ));
  };

  return (
    <div className={styles.menuContainer}>
      <nav className={`${styles.header}`}>
        <ul className={styles.links}>{renderCategories()}</ul>
        <button className={styles.hamburger} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </nav>
    </div>
  );
}

export default GamesMenuNew;
