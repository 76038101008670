import { useEffect, useState } from "react";
import styles from "./payment-success.module.css"
import { useNavigate } from "react-router-dom";
import { trackGoal } from "../../hooks/track-goal";

export default function PaymentSuccessPage() {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(5);

  useEffect(() => {
    trackGoal("cabinet_tarif_typage");

    const intervalId = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (timeLeft <= 0) {
      navigate("/games");
    }
  }, [timeLeft, navigate]);

  return (
    <h3 className={styles.header}>
      Оплата прошла успешно!🎉 Спасибо!
      <br />
      Вы будете перенаправлены на страницу игр через {timeLeft} сек.
    </h3>
  );
}
