import React from "react";
import { Helmet } from "react-helmet-async";

export default function LayoutTitleDescription({
  pageTitle,
  pageDescription,
  children,
}) {
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>

      {children}
    </>
  );
}
