import { useState } from "react";
import styles from "./app-header.module.css";
import { NavLink } from "react-router-dom";
import { postUserLogout } from "../api/logout";
import { REDIRECT_URL } from "../api/api";
import { trackGoal } from "../../hooks/track-goal";

function AppHeader() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const logout = () => {
    postUserLogout()
      .then(() => {
        document.cookie =
          "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

        const landingUrl = REDIRECT_URL;

        window.location.href = landingUrl;
      })
      .catch((error) => {
        console.error("Ошибка при выходе из системы:", error);
      });
  };
  const handleCLickLink = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className={`${styles.header}`}>
      <div className={styles.logoContainer}>
        <img
          className={styles.logo}
          src="../images/logo-new.svg"
          alt="Логотип EdLand"
        />
        <h3 className={styles.companyName}>EDLAND</h3>
      </div>
      <nav>
        <ul className={styles.links}>
          <li>
            <NavLink
              to="/games/"
              className={({ isActive }) =>
                isActive ||
                window.location.pathname === "/games" ||
                window.location.pathname === "/"
                  ? styles.activeLink
                  : ""
              }
              onClick={() => trackGoal("click_games_cabinet_header")}
            >
              Игры
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/profile/"
              className={({ isActive }) => (isActive ? styles.activeLink : "")}
            >
              Профиль
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/subscription/"
              className={({ isActive }) =>
                isActive ||
                window.location.pathname.startsWith("/subscription/")
                  ? styles.activeLink
                  : ""
              }
            >
              Подписка
            </NavLink>
          </li>

          {/* <li className={styles.unavaliable}>
            <a href="#">Магазин</a>
          </li> */}
          {/* <li className={styles.unavaliable}>
            <a href="#">Консультации</a>
          </li> */}
        </ul>
        <button
          className={`${styles.hamburger} ${isMenuOpen ? styles.cross : ""}`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </nav>
      {isMenuOpen && (
        <ul className={styles.mobileMenu}>
          <button className={styles.btnLoginMobile} onClick={logout}>
            Выйти
          </button>
          <li onClick={handleCLickLink}>
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? styles.activeLink : "")}
            >
              Профиль
            </NavLink>
          </li>
          <li onClick={handleCLickLink}>
            <NavLink
              to="/subscription/"
              className={({ isActive }) =>
                isActive ||
                window.location.pathname.startsWith("/subscription/")
                  ? styles.activeLink
                  : ""
              }
            >
              Подписка
            </NavLink>
          </li>
          <li onClick={handleCLickLink}>
            <NavLink
              to="/games/"
              className={({ isActive }) => (isActive ? styles.activeLink : "")}
            >
              Игры
            </NavLink>
          </li>
          {/* <li onClick={handleCLickLink}>
            <a href="#">Магазин</a>
          </li> */}
          {/* <li onClick={handleCLickLink}>
            <a href="#">Консультации</a>
          </li> */}
        </ul>
      )}

      <button className={styles.btnLogin} onClick={logout}>
        Выход
      </button>
    </header>
  );
}

export default AppHeader;
